<template>
  <div class="col-xl-9 col-lg-12 col-md-12">
    <div class="card mb-0">
      <div class="card-header">
        <h3 class="card-title">Faturalar</h3>
        <router-link
          to="/profile/billing/address"
          class="btn btn-primary"
          style="position: absolute; right: 25px"
          >Fatura Bilgileri</router-link
        >
      </div>
      <div class="card-body">
        <div class="item2-gl">
          <div class="input-group mb-4">
            <input
              type="text"
              class="form-control"
              id="searchAppointment"
              placeholder="Faturalarda Ara"
            />
            <button type="button" class="btn btn-primary">ARA</button>
          </div>
          <div class="row mb-2">
            <div class="col-4">
              <button
                :class="
                  'btn w-100 btn-primary' +
                  (selectedStatus == 2 ? ' active' : '')
                "
                @click="statusChange(2)"
              >
                ÖDEME BEKLEYEN
              </button>
            </div>
            <div class="col-4">
              <button
                :class="
                  'btn w-100 btn-primary' +
                  (selectedStatus == 1 ? ' active' : '')
                "
                @click="statusChange(1)"
              >
                ÖDENMİŞ
              </button>
            </div>
            <div class="col-4">
              <button
                :class="
                  'btn w-100 btn-primary' +
                  (selectedStatus == 0 ? ' active' : '')
                "
                @click="statusChange(0)"
              >
                MUHASEBE ONAYINDA BEKLEYEN
              </button>
            </div>
          </div>
          <div class="tab-content">
            <div class="tab-pane active" id="tab-11">
              <div
                class="card overflow-hidden"
                v-for="(item, i) in list"
                :key="'comment-' + i"
              >
                <div class="d-md-flex" :id="'#' + i">
                  <div class="card border-0 mb-0">
                    <div class="card-body">
                      <div class="item-card9">
                        <b>ŞİRKET/ŞAHIS ÜNVANI:</b>{{ item.account_title
                        }}<br />
                        <b>ŞİRKET TÜRÜ :</b
                        >{{
                          item.company_type == 1
                            ? "ŞAHIŞ ŞİRKETİ"
                            : "LİMİTED/ANONİM ŞİRKET"
                        }}<br />
                        <b>ADRES :</b>{{ item.address }}<br />
                        <b>DURUMU:</b
                        >{{
                          item.payment_status == 3
                            ? "MUHASEBE ONAYINDA"
                            : item.payment_status == 1
                            ? "ÖDENDİ"
                            : "ÖDEME BEKLİYOR"
                        }}
                        <p class="mb-0 leading-tight">{{ item.description }}</p>
                      </div>
                      <a class="pdf" v-if="item.file!=null" target="_blank" :href="'https:storage.terapivitrini.com/'+item.file"><i class="fa fa-file-pdf-o" aria-hidden="true"></i></a>
                    </div>
                    <div class="card-footer py-4">
                      <div class="row">
                        <div class="col-6">
                          <div class="item-card9-footer">
                            <div class="ms-auto">
                              <div class="row w-100">
                                <div class="col-6">KDV</div>
                                <div class="col-6">
                                  <b>{{ item.tax_price }}TL</b>
                                </div>
                              </div>
                              <div class="row w-100">
                                <div class="col-6">TOPLAM</div>
                                <div class="col-6">
                                  <template v-if="item.dumping_mode == 0">
                                    <b>{{ item.price }}TL</b>
                                  </template>
                                  <template v-if="item.dumping_mode == 1">
                                    <b
                                      ><del>{{ item.price }}</del></b
                                    ><br />
                                    <b>{{ item.dumping_price }}TL</b>
                                  </template>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-3">
                          <button
                            v-if="item.payment_status == 2"
                            @click="paymentDirect(item)"
                            class="btn btn-primary w-100"
                          >
                            ÖDEME YAP
                          </button>
                        </div>
                        <div class="col-3">
                          <button
                            @click="billingDetail(item)"
                            class="btn btn-primary w-100"
                          >
                            FATURA DETAYLARI
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="center-block text-center">
          <Pagination :currentpage="currentpage" :pagesize="pagesize" :preload="'/profile/billing/'" />
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.active{
  border-bottom: 5px solid #ffffff87 !important;
    padding-bottom: 12px;
    transition:.15s ease-in-out;
}
.pdf{
  position: absolute;
  right:20px;
  top:10px;
  font-size:50px
}
.pdf:hover{
  cursor: pointer;
  color: #bb7494;
}
</style>
<script>
import Pagination from '../../../../components/Pagination.vue';
export default {
  created() {
    document.title = "Faturalar";
    this.getLink();
  },
  data() {
    return {
      list: [],
      selectedStatus: 2,
      totalsize: 0,
      pagesize: 0,
      perpage: 20,
      currentpage:2,
      billLoad:false,
    };
  },
  methods: {
    statusChange(i) {
      this.selectedStatus = i;
      this.getLink();
    },
    paymentDirect(item) {
      this.$router.push("/profile/billing/payment/" + item.billing_id);
    },
    billingDetail(item) {
      this.$router.push("/profile/billing/payment-detail/" + item.billing_id);
    },
    getLink() {
      let info = {
        page: 1,
        perpage: this.perpage,
        selectedStatus: this.selectedStatus,
      };
      this.list = [];
      this.$store.dispatch("myBillings", info).then((value) => {
        this.list = value.list;
        this.totalsize = value.size;
        this.pagesize = value.pages;
        this.billLoad = false;
      });
    },
    save() {
      document.getElementById("saveButton").disabled = true;
      document.getElementById("saveButton").innerHTML =
        "Bilgiler kayıt ediliyor..";
      let about = document.querySelector("textarea[name=about]").value;
      let biographieSave = {
        about: about,
        skill: JSON.stringify(this.skillList),
        education: JSON.stringify(this.educationList),
      };
      this.$store.dispatch("biographieUpdate", biographieSave).then((value) => {
        document.getElementById("saveButton").disabled = false;
        document.getElementById("saveButton").innerHTML = "Biyografimi Kaydet";
      });
    },
  },
  components: { Pagination },
};
</script>